import React from "react";
import styled from "styled-components";
import { Radio } from "antd";

const StyledRadio = styled(Radio.Group)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #464797;
  }
  .ant-radio-checked .ant-radio-inner:hover {
    border-color: #464797;
  }
  .ant-radio-inner::after {
    background-color: #464797;
  }
`;

const RadioWrapper = (props) => {
  return <StyledRadio {...props}></StyledRadio>;
};

export default RadioWrapper;
