//Left side bar menu content 
import React from "react";
import { Layout,Menu } from "antd";
import MenuWrapper from "../menu";
import { Link , Route , Switch , BrowserRouter } from 'react-router-dom'
import IconWrapper from '../Icon'
import { Col } from 'antd'
import Icon from '../Icon/index'

// src\components\sidebarMenus\RightSidebarMenu.js

const LeftSideBar = (props) => {
  return(
    <Layout>
    <MenuWrapper
    defaultSelectedKeys={["1"]}
    >   
                   
            <Menu.Item key="1">
               
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/'>Dashboard</Link></Col>
                </Col>
              
            </Menu.Item>    

        
            <Menu.Item key='2'>

                <Col>
                    <Col span={6}> <Icon type={"user"}></Icon> </Col>
                    <Col span={16}><Link to='/customers'>Cutomers</Link></Col>
                </Col>
                                
            </Menu.Item>

            <Menu.Item key="3">
                <Col span={24}>
                    <Col span={6}><Icon type={"user"}></Icon></Col>
                    <Col span={16}><Link to ="/salons"><p>Salons</p></Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key="4">
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/services'>Services</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='5'>
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/payments'>Payments</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='6'>
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/offers'>Offers</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='7'>
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/coupons'>Coupons</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='8'>
                <Col span={24}>
                    <Col span={6}><Icon  type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/subscriptions'>Subscriptions</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='9'>
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/manage-points'>Mannage Points</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='10'>
                <Col span={34}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/email-push-notifications'>Email and Push Notifications</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='11'>
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/languages'>Languages</Link></Col>
                </Col>
            </Menu.Item>

            <Menu.Item key='12'>
                <Col span={24}>
                    <Col span={6}><Icon type={'user'}></Icon></Col>
                    <Col span={16}><Link to='/help'>Help</Link></Col>
                </Col>
            </Menu.Item>


        </MenuWrapper>
        </Layout>
  

  );
};

export default LeftSideBar;