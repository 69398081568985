import React from "react";
import styled from "styled-components";
import { Select, Icon } from "antd";

const { Option } = Select;
const StyledInput = styled.option`
  .select-option {
    backgorund-color: red;
    height: 40px;
    border: none;
  }
`;

const OptionsWrapper = (props) => {
  return <StyledInput {...props}>{props.children}</StyledInput>;
};

export default OptionsWrapper;
