import React from "react";
import styled from "styled-components";
import { Input } from "antd";

// const StyledInput = styled(Input).attrs(() => ({ size: "large" }))`
//   input {
//     background-color: ${(props) =>
//       props.theme[props.theme.mode].background.primary};
//     color: ${(props) => props.theme[props.theme.mode].textColor.primary};
//     border: 1px solid black;
//     border-radius: 6px !important;
//   }
// `;

// const InputWrapper = (props) => {
//   return props.password ? (
//     <StyledInput as={Input.Password} {...props}></StyledInput>
//   ) : (
//     <StyledInput {...props}></StyledInput>
//   );
// };

const StyledInput = styled(Input)`
  border-radius: 6px;
  box-shadow: #49d3ce;
  border-color: ${(props) =>
    props.style ? props.style.borderColor : "gray !important"};
  outline: none !important;
  // color: #49d3ce;
`;

const InputWrapper = (props) => {
  return props.password ? (
    <StyledInput as={Input.Password} size="large" {...props}></StyledInput>
  ) : (
    <StyledInput size="large" {...props}></StyledInput>
  );
};

export default InputWrapper;
