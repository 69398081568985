import React from "react";
import { Layout } from "antd";
import styled from "styled-components";

import HeaderContent from "./HeaderContent";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  background: ${(props) => props.theme[props.theme.mode].background.primary};
  color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  padding: 0 100px 0 24px;

  box-shadow: 0 -6px 22px 0px rgba(0, 0, 0, 0.15);
  z-index: 100;

  position: fixed;
  width: 100%;
  height: 64px;

  text-align: left;

  && .headerIcon {
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  }
  //   font-weight: bold;
  //   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
`;

const HeaderWrapper = (props) => {
  console.log("HeaderWrapper", props);
  return (
    <StyledHeader>
      <HeaderContent
        dispatch={props.dispatch}
        state={props.state}
        toggleCollapsed={props.toggleCollapsed}
      />
    </StyledHeader>
  );
};

export default HeaderWrapper;
