import React from "react";
import styled from "styled-components";
import Icon from "@ant-design/icons";

const StyledIcon = styled(Icon)`
  font-size: 13px;
  cursor: pointer;
  color: #1d194d;
  &:hover {
    color: #fa7268;
  }
`;

const IconWrapper = (props) => {
  return <StyledIcon {...props}>{props.children}</StyledIcon>;
};

export default IconWrapper;
