import React, { useState, useEffect } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import ReactTable from "react-table";
import "react-table/react-table.css";
import styled from "styled-components";
import Style from "./Style";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../configurations/Constants";

const StyledTable = styled(ReactTable)`
  ${Style}
`;

const TableWrapper = (props) => {
  // console.log("Table Data in table wrapper: ", props.tableData.sort());
  // let loading;
  // if (props.tableData === null || props.tableData === undefined) {
  //   loading = true;
  // } else {
  //   loading = false;
  // }
  console.log("Table Data Props >>>>", props.tableData);

  return (
    <StyledTable
      {...props}
      data={props.tableData ? props.tableData : null}
      noDataText="No data found"
      showPageSizeOptions={false}
      loading={props.loading}
      columns={props.content.map((val) => ({
        Header: val.sortable ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <div
              className="sortingHeader"
              style={{
                display: "flex",
                fontSize: "1.3rem",
                flexDirection: "column",
                marginRight: 10,
              }}
            >
              <div className="ascending">
                <FiChevronUp />
              </div>
              <div className="descending">
                <FiChevronDown />
              </div>
            </div>
            {val.name}
          </div>
        ) : (
          val.name
        ),
        id: val.id,
        sortable: val.sortable || false,
        Cell: val.render ? val.render : (r) => r.original[val.id],
      }))}
      defaultPageSize={DEFAULT_TABLE_PAGE_SIZE}
      pageSize={props.pageSize || DEFAULT_TABLE_PAGE_SIZE}
      minRows={5}
      resizable={false}
      pageText={""}
      previousText={"‹"}
      nextText={"›"}
    />
  );
};

export default TableWrapper;
